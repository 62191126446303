<template>
    <section class="warehouse">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-button size="small" type="primary" @click="openAdd">新建</el-button>
        </div>
        <div style="padding: 10px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="rentTableRef" :dataRequest="getStockList" :columns="warehouseTableColumn"
                       :query="formSearch" :height="750">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="details(row)">详情</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <dialog-add-warehouse ref="dialogAddWarehouse" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
    import {warehouseTableColumn} from "@/views/property-management/assets-management/data";
    import {getStockList,stockerPublish} from '@/api/warehouse';
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";

    export default {
        name: "warehouse",//api/assets/stock/list?page=1&pageSize=10
        data() {
            return {
                warehouseTableColumn,
                formSearch: {},
                dialogVisible: false,
                formPublish: {
                    name: "",
                    mobile: '',
                    stocksUuid: ''
                },
                rules: {
                    name: [{required: true, message: '请输入入库人', trigger: 'blur'},
                        {pattern: /[\u4e00-\u9fa5]/, message: '联系人只能输入中文'},],
                    mobile: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                        {pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: '请输入正确的手机号码'},
                    ],
                }
            };
        },
        components: {
            dialogAddWarehouse: () => import("./components/dialog-add-warehouse")
        },
        methods: {
            getStockList(params) {
                return getStockList(params);
            },
            openAdd() {
                this.$refs["dialogAddWarehouse"].openDialog();
            },
            handleSearch() {
                this.$refs["rentTableRef"].pNumber = 1;
                this.$refs["rentTableRef"].getTableData();
            },
            details(data) {
                let {uuid} = data;
                this.$router.push({name:"assets-warehousing", params:{companyStockUuid:uuid}});
            },
        },
        async mounted() {
        },
        watch: {}
    }
</script>
<style lang="scss" scoped>
    .warehouse {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                //white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>
